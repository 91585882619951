import { Component, Inject, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpParams } from '@angular/common/http';
import { driverDetailComponent } from '../driverDetail/driverDetail.component';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, LoginModel } from '../_services/authentication.service';
import { Key } from 'protractor';
import { Options } from 'selenium-webdriver/edge';
import { map } from 'rxjs/operators';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}

interface convicton {
  driverState: string;
  driverLicenseNumber: string;
  convictionJurisdictionCode: string;
  convictionCitationDate: string;
  convictionDate: string;
  convictionCourtType: string;
  convictionJurisOffenseReference: string;
  convictionAcdCode: string;
  convictionId: string;
  convictionCourtLocator: string;
  convictionAcdCodeDetail: string;
  convictionDriverCdlHolder: string;
  convictionCommercialVehicle: string;
  convictionHazmatVehicle: string;
  convictionUserId: string;
  convictionDateReceived: string;
  convictionDatePosted: string;
  convictionCategory: string;
  convictionDatePostedDisplay: string;
}

@Component({
  selector: 'app-conviction-component',
  templateUrl: './conviction.component.html',
  styleUrls: ['./conviction.component.css']
})


export class ConvictionComponent implements OnInit {
  maxStartDate = new Date();
  setFromDate = new Date();
  setToDate = new Date();
  minDate = new Date(1000, 0, 1);
  startDateInvalid: boolean;
  public error: boolean;
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";

  private loginState: string;

  public convictonListObservable: Observable<convicton[]>;
  displayedConvictionData: string[];
  constructor(_http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private idleTimeoutSvc: SessionService, private elementRef: ElementRef, public dialog: MatDialog, private spinner: NgxSpinnerService, private authService: AuthenticationService) {
    this.baseUrl = _baseUrl;
    this.http = _http;
    this.authService.currentUser.subscribe(g => this.loginState = g.state);
    this.setFromDate.setDate(new Date().getDate() - 5);
    this.setToDate.setDate(new Date().getDate());
    this.startDateInvalid = false;
    this.startDateFormControl.setValue(this.setFromDate.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    this.endDateFormControl.setValue(this.setToDate.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    this.startDateform.valueChanges.subscribe(g => {
      this.startDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });

    });
    this.endDateform.valueChanges.subscribe(g => {
      this.endDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });

    });

    this.startDateFormControl.valueChanges.subscribe(g => {
      if (this.startDateFormControl.valid) {
        this.startDateform.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.startDateform.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.endDateFormControl.valueChanges.subscribe(g => {
      if (this.endDateFormControl.valid) {
        this.endDateform.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.endDateform.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });



  }

  ngOnInit() {
  }
  errorMessage: string = "";
  http: HttpClient;
  baseUrl: string;
  userInfo: Observable<User>;
  public getConvictions() {
    if (this.endDateFormControl.invalid || this.startDateFormControl.invalid) {
      this.endDateFormControl.markAsTouched();
      this.startDateFormControl.markAsTouched();
    }
    else if (new Date(this.endDateFormControl.value) < new Date(this.startDateFormControl.value)) {
      this.error = true;
      this.errorMessage = "The End Date must be equal to or greater than the Start Date.";
    }
    else {
      this.spinner.show();
      this.authService.currentUser.subscribe(g => {
        if (g != null) {
          var Account = new LoginModel();
          Account.Password = "xxxxxxxxx";
          Account.UserID = g.userID;
          this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
          this.userInfo.subscribe(g => {
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser', JSON.stringify(g));
          });
        }
      });
      this.displayedConvictionData = ['Actions', 'driverState', 'driverLicenseNumber', 'convictionCitationDate', 'convictionDate', 'convictionId', 'convictionJurisdictionCode', 'convictionCourtType', 'convictionAcdCode', 'convictionAcdCodeDetail', 'convictionDriverCdlHolder', 'convictionCommercialVehicle', 'convictionHazmatVehicle', 'convictionCourtLocator', 'convictionJurisOffenseReference', 'convictionCategory', 'convictionUserId', 'convictionDatePosted'];
      let Params = new HttpParams();
      Params = Params.append("state", this.loginState);
      Params = Params.append("startdate", this.startDateFormControl.value);
      Params = Params.append("enddate", this.endDateFormControl.value);
      this.convictonListObservable = this.http.get<convicton[]>(this.baseUrl + 'api/Data/Convictions', { params: Params });
      this.convictonListObservable.subscribe(g => {
        if (g.length <= 0) {
          this.error = true;
          this.errorMessage = "No Records to show.";
        }
        else {
          this.error = false;
        }
      });
    }
    this.spinner.hide();
  }


  startDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  endDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  startDateform = new FormControl({ value: this.setFromDate, disabled: false });
  endDateform = new FormControl({ value: this.setToDate, disabled: false });
  openDialog(): void {
    this.dialog.open(driverDetailComponent, { width: '90%' });
  }
}
